import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container } from '@components/global';
import { Link } from "gatsby";

import rocket from './../../images/art/undraw_to_the_moon_v2mv.svg';
import ExternalLink from '@common/ExternalLink';
import { SVG } from '@svgdotjs/svg.js';

//import axios from 'axios';

const axios = require('axios');
axios.defaults.withCredentials = true;

class Header extends React.Component {
  componentDidMount() {

    axios.get('https://rocketbatch.com:443/server/api/registration', {withCredentials: true})
    .then(function (response) {
      // handle success
      
      console.log(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });

  }
  constructor(props) {
    super(props);
    this.state = {
      info: ""
    }
  }

  validateEmail = (mail) => {
    // {!this.state.isHidden && <Child />}

    console.log("email1:"+mail);

    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
      return true;      
    else
      return false;
  };
  
  mySubmit = () => {
    let email = document.forms["form"]["email"].value;
    var retval = "";
    let vm = this;

    if(this.validateEmail(email)){
      var config = {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              withCredentials: true
          }
      };

      var body = new URLSearchParams();
      body.append('email', email);



     


      //axios.post("http://localhost:8081/server/api/registration",body,config)
      axios.post("https://rocketbatch.com:443/server/api/registration",body,config)
      .then(function (response) {
        console.log("success:",response.data);

        vm.setState({ info: response.data });  
        setTimeout(() => {
          vm.setState({ info: ""});
          document.forms["form"]["email"].value = "";
        }, 15000);
      })
      .catch(function (error) {
        console.log(error);
      });


      
    }
    else{
      if(email.length==0)
        retval = "Oh, quite empty.. an email address would be enough.";      
      else
        retval = "It looks you have entered an invalid email address!";     

      this.setState({ info: retval });  
    }
  };

  render() {
      return(
        <StaticQuery
        query={graphql`
          query {
            art_build: file(
              sourceInstanceName: { eq: "art" }
              name: { eq: "nathan-dumlao-BAjk5GtexaI-unsplash" }
            ) {
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        `}
        render={data => (
          <HeaderWrapper >
            <Container style={{position:"relative", zIndex:"100"}}>
              <Grid> 
                <Text>
                  <h1 style={{color:"#fff", marginBottom:"0px"}}>
                    Better
                  </h1>

                  <h1 style={{color:"#fff", marginBottom:"0px"}}>
                    communication.
                  </h1>

                  <h1 style={{color:"#fff", marginBottom:"40px"}}>
                    Better mojo.
                  </h1>

                  <h3 style={{color:"#fff"}}>Keep your team aligned. Keep your family aligned. Simple.</h3>

                  <h4 style={{color:"#fff", marginTop:"40px"}}>The interaction based collaboration platform.</h4>
                </Text>
              </Grid>

              <CenteredContainer> 
              
              <form id="form">
                <EmailField type="email" name="email" id="email" placeholder="Enter your email"></EmailField>
                <SubmitButton onClick={this.mySubmit}>
                    Request Access
                </SubmitButton>
              </form>
              </CenteredContainer>
              
              <Child>
                {(this.state.info.length>0) ? this.state.info : <span>&nbsp;&nbsp;</span>}
              </Child>

            </Container>
            <Img
                fluid={data.art_build.childImageSharp.fluid}
                style={{ height: '100%', width: '100%', maxWidth: '100%',  marginBottom: "300px", left: "0px", top:"0px", position:"absolute", zIndex: "0" }}
              />
          </HeaderWrapper>
        )}
      />
      )
  }
}



const Child = styled.p`
    color:#fff;
    marginTop:10px;
    fontWeight:bold;
`;

const HeaderWrapper = styled.header`
  background-color: white;
  padding-top: 300px;
  padding-bottom: 100px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 128px;
  }
`;

const Art = styled.figure`
  width: 75%;
  margin: 0;

  position: relative;
  
  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  align-items: center;
  grid-gap: 64px;
  background-color: transparent;
  padding: 30px 50px;
  z-index: 100;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    padding: 30px 0px;
    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;


  }
`;

const StyledExternalLink = styled(Link)`
  border-radius: 0px;
  vertical-align: middle;
  padding:10px 30px;
  
  text-align:center;
  align-items: center;
  text-decoration: none;

  border-style: solid;
  border-width: 2px;
  border-color: #F8A501;
  color: #fff;
  background-color: #F8A501;

  border-radius: 8px;
  &:hover  {
    border-style: solid;
    border-width: 2px;
    border-color: #F8A501;
    color: #F8A501;
    background-color: #fff;
  }
`;


const SubmitButton = styled.span`
  border-radius: 0px;
  vertical-align: middle;
  padding:10px 30px;

  text-align:center;
  align-items: center;
  text-decoration: none;

  border-style: solid;
  border-width: 2px;
  border-color: #F8A501;
  color: #fff;
  background-color: #F8A501;

  border-radius: 8px;
  &:hover  {
    border-style: solid;
    border-width: 2px;
    border-color: #F8A501;
    color: #F8A501;
    background-color: #fff;
  }

`;


const EmailField = styled.input`
  margin-right: 20px;
  border-radius: 8px;
  vertical-align: middle;
  padding:10px 30px;

  text-align:center;
  align-items: center;
  text-decoration: none;
  border-style: solid;
  border-width: 2px;
  border-color: #F6F6F6;
  color: #F8A501;
  background-color: #F6F6F6;
  
  

  &:hover  {
    border-style: solid;
    border-width: 2px;
    border-color: #F8A501;
    color: #F8A501;
    background-color: #fff;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    

  }


`;


const CenteredContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: left;
  

  @media (max-width: ${props => props.theme.screen.xs}) {
    display:none;
  }

`;

export default Header;

/*
<StyledExternalLink to="/downloads/">
  Download rocketbatch &nbsp;&#x2794;
</StyledExternalLink>
*/