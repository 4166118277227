import React from 'react';
import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Header from '@sections/Header';
import About from '@sections/About';
import Start from '@sections/Start';
import Showcase from '@sections/Showcase';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
   
    <Header />
    <Navbar />
    <Showcase />
    {/* 
    <About />
   */}
    <Start />
    
    <Footer />

  </Layout>
);

export default IndexPage;