import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import { Link } from "gatsby";
import { Container } from '@components/global';
import TwitterIcon from '@static/icons/twitter.svg';
import styled from 'styled-components';
import ExternalLink from '@common/ExternalLink';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from './style';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';
import { ReactComponent as Logo } from '@images/logos/logo-rocketbatch-monochrome.svg';
import { GlobalLink } from '@components/Link';

const NAV_ITEMS = [ 'Features' ];/*, 'Faq' */

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = item => (

    <AnchorLink href={`#${item.toLowerCase()}`} onClick={this.closeMobileMenu}>
      {item}
    </AnchorLink>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
        
      >

        <TwitterLink>
          <ExternalLink href="https://twitter.com/rocketbatch/" >
                <img src={TwitterIcon} alt="link" style={{verticalAlign:"middle"}}/><span> Follow on Twitter </span>
          </ExternalLink>
        </TwitterLink>

        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
        
        <NavItem key={2}><Link to="story">Our Story</Link></NavItem>
        
       
       {/*

       <NavItem key={3}><Link to="downloads">Download</Link></NavItem>
        <NavItem key={4}><Link to="pricing">Pricing</Link></NavItem>

        <NavItem key={5}><Link to="blog">Blog</Link></NavItem>

        <NavItem key={5}>
          <GlobalLink to="https://team.rocketbatch.com/" style={{color:'#0056E5'}}>
                    Login
          </GlobalLink>
        </NavItem>
        */}
      </Scrollspy>
      
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;

    return (
      <Nav {...this.props} style={{background:"transparent"}}>
        <StyledContainer>
         <Brand as="a" href="/"><Logo style={{height:"46px",width:"37px"}}/></Brand> 

          <Mobile>
            <button onClick={this.toggleMobileMenu} style={{ color: '#F22789' }}>
              <MenuIcon />
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}




const TwitterLink = styled.div`
  margin-right: 20px;
  border-radius: 8px;
  vertical-align: middle;
  padding:7px 30px;
  transform: translateY(-5px);


  text-align:center;
  align-items: center;
  text-decoration: none;
  border-style: solid;
  border-width: 2px;
  border-color: #F6F6F6;
  color: #fff;
  background-color: #F6F6F6;

  &:hover  {
    border-style: solid;
    border-width: 2px;
    border-color: #F8A501;
    color: #F8A501;
    background-color: #fff;
  }
`;


export default Navbar;
