import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_blooming_jtv6" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_gravitas_d3ep" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_inspiration_lecl" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>Speed past the competition</h2>
              <p>
              <strong>rocketbatch combines some of the boldest applications</strong>. Instead of
                using many tools, lift productivity and lower cost using a single tool — <strong>slack, trello, asana, wunderlist, outlook and dropbox delivered
                in one fluent communication tool</strong>.
                <br />
                <br />
                Ready wherever you are.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Partnering</h2>
              <p>
                We use a single core technology.
                This brings the power to build a native desktop application as well
                a browser version with much lower resources. Perfect to you - <strong>lower
                resources, lower costs</strong>. And perfect to get in touch for
                your personal extensions build on top of our default client.

                <br />
                </p>
                <br />
                <br />

                
                <StyledExternalLink href="https://team.rocketbatch.com/">
                    Start in Browser &nbsp;&#x2794;
                </StyledExternalLink>

              <h5>It is in Beta-Mode but more than useful.</h5>
             
            </div>
          </Grid>
          
          <Grid>
            <div>
              <h2>Focus on your ideas</h2>
              <p>
                Waste no more time on tooling. Waste no more money on multiple
                solutions and its educational costs.
                <br />
                <br />
                <strong>Boost productivity while focusing on one tool
                with a neat and intuitive user interface</strong>.
                <br />
                <br />
                Up to 100 contacts and 10 projects - 5gb of storage
                included. <strong>rocketbatch is free for personal use</strong> (including your family). You need more?
                <br />
                <br />
                Businessplans starting from 19 CHF per user per month.
                
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 100px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 150px 0 0 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const StyledExternalLink = styled.a`
  color: #0056E5;
  text-decoration: none;
  font-size: 20px;
  line-height: 30px;
  &:hover {
    color: ${props => props.theme.color.link};
  }
`;
const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;