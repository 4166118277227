import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';
import { Link } from "gatsby";
import { GlobalLink } from '@components/Link';

const UsedBy = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "savings" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="starting" accent>
  <Container>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_story.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Lower your costs?</h2>
            
              <div>
              <h3>No credit cards. No setup fees. No obligations.</h3>
              <div style={{color:"#211E26", paddingTop:"30px"}}>
              Try it for free for 30 days.
              No worries! We will contact you before the end of the 30 days and won't bill
              you without get back to you.
              </div>


              <br />
              <br />
              <span style={{color:'#211E26'}}>
              Secure 256-Bit TLS connection  - which is used by all banks worldwide. All password are salted and encrpyted.
              All saved in the world most secure cloud computers. 
              </span>

              </div>
              <br />

            



             
             {/*

              <GlobalLink to="/downloads/">
                  Download&nbsp;&#x2794;
              </GlobalLink>


            */}


            </div>
          </Grid>

          </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 100px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 0px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;


const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;


export default UsedBy;
