import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const SHOWCASE = [
  {
    name: 'Tasks / Appointments',
    image: 'tasks.png',
    role: 'You know wunderlist? But want more? This is for you - connected to anything. Feature rich with Documents, Activity recordings, Chats, Notes. ',
  },
  {
    name: 'Mailintegration',
    image: 'mails.png',
    role: 'Ever wanted to drop outlook? Now you can. Your mail conversation assigned and shared. Smart. Tracked.',
  },
  {
    name: 'Relationships',
    image: 'contacts.png',
    role: 'See the hole picture - collaboration made easy',
  },
  {
    name: 'Personal Kanban / Lists / Calendar',
    image: 'calendar.png',
    role: 'Your mode - best for you',
  },
  {
    name: 'Leads / Deal flow / Recruitment',
    image: 'leads.png',
    role: 'Visuallized pipeline for any need',
  },
  {
    name: 'Projects / Notes',
    image: 'scrumboard.png',
    role: 'All your belongings at one place and still easily connected.',
    
  },
  {
    name: 'Workspaces',
    image: 'workspaces.png',
    role: 'Organize your life - private and professionally. Securely separated. Easily accessible.',
  },
  {
    name: 'Fo all platforms',
    image: 'platforms.png',
    role: 'Windows / MacOS / Linux & soon on the web',
  },
];

const Showcase = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_showcase: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "connections" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="features" accent="secondary">
        <Container style={{ position: 'relative' }}>
          <h1><Header>Features</Header></h1>
          <ShowcaseGrid>
            {SHOWCASE.map(({ name, image, role }) => {
              const img = data.allFile.edges.find(
                ({ node }) => node.relativePath === image
              ).node;

              return (
                <div>
                  <Img fluid={img.childImageSharp.fluid} alt={name} /> 
                  <Title>{name}</Title>
                  <Subtitle>{role}</Subtitle>
                </div>
              );
            })}
          </ShowcaseGrid>
          
          {/*
          <Art>
            <Img fluid={data.art_showcase.childImageSharp.fluid} />
          </Art>
          <ArtMobile>
            <Img fluid={data.art_showcase.childImageSharp.fluid} />
          </ArtMobile>
          */}
          
        </Container>
      </Section>
    )}
  />
);

const Header = styled.div`
color: #fff;

/*xs: '575px',
sm: '767px',
md: '991px',
lg: '1199px',*/

margin-top:-130px;

@media (max-width: ${props => props.theme.screen.lg}) {
  margin-top:-130px;
}

@media (max-width: ${props => props.theme.screen.md}) {
  margin-top:20px;
}

@media (max-width: ${props => props.theme.screen.sm}) {
  margin-top:10px;
}

@media (max-width: ${props => props.theme.screen.xs}) {
  margin-top:40px;  
  
}
`;

const ShowcaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  grid-template-rows: min-content;
  grid-gap: 50px;
  justify-content: space-between;
  width: 100%;
  margin-top: 72px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 0;
  left: 60%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 50%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

const Title = styled.p`
  margin-top: 16px;
  color: ${props => props.theme.color.black.regular};

  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 14px;
  }

`;

const Subtitle = styled.p`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.light};


  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 12px;
  }
`;

export default Showcase;
